
import { components } from '~/slices'
import prismicCustomTypePageContent from '~/mixins/prismicCustomType/single'
import headData from '~/mixins/headData'

const type = 'home'

export default {
  name: 'Home',
  mixins: [prismicCustomTypePageContent({ type }), headData],
  data() {
    return { components }
  },
}
